import { Text } from "@clipboard-health/ui-react";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Dialog, DialogContent, Stack, capitalize } from "@mui/material";
import { ShiftStages } from "@src/lib/interface/src";

import { getStageText } from "../Stage";

export function ClockSuccessAlertDialog({
  isVisible,
  updatingStage,
}: {
  isVisible: boolean;
  updatingStage?: ShiftStages;
}) {
  const stageText = updatingStage
    ? capitalize(getStageText(updatingStage).toLowerCase())
    : undefined;

  return (
    <Dialog open={isVisible}>
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <DoneOutlineIcon color="success" sx={{ fontSize: "2rem" }} />
          <Text>{stageText || "Action"} succeeded!</Text>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
