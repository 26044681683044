import { IonAlert, IonButton, IonSpinner } from "@ionic/react";
import { logEvent } from "@src/appV2/lib/analytics";
import { ShiftStateData } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { SHIFT_STRINGS, USER_EVENTS } from "@src/constants";
import { Shift, ShiftStages } from "@src/lib/interface";
import { makeInstantpayLogParameters } from "@src/utils/logEvent";
import { Fragment, useState } from "react";

export const SkipLunchButton: React.VFC<{
  stage: ShiftStages;
  shift: Shift;
  shiftStateData?: ShiftStateData;
  onClick: () => void;
  loading: boolean;
}> = ({ stage, shift, onClick, loading, shiftStateData }) => {
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  if (stage !== ShiftStages.LUNCH_OUT) {
    return <Fragment />;
  }

  const isMandatoryBreakTextVisible = shiftStateData?.metadata.requiresLunchBreak;

  return (
    <Fragment>
      <IonButton
        className="ion-margin"
        fill="outline"
        shape="round"
        size="large"
        expand="block"
        disabled={loading}
        onClick={() => {
          logEvent(
            USER_EVENTS.TAPPED_SKIP_BREAK,
            makeInstantpayLogParameters(
              shift,
              shift.isInstantPay,
              undefined,
              shiftStateData?.metadata.requiresLunchBreak
            )
          );
          setIsConfirm(true);
        }}
      >
        {loading && <IonSpinner name="crescent" />}
        {SHIFT_STRINGS.SKIP_BREAK}
      </IonButton>
      <IonAlert
        message={
          isMandatoryBreakTextVisible
            ? SHIFT_STRINGS.ARE_YOU_SURE_YOU_WANT_TO_SKIP_MANDATORY_BREAK
            : SHIFT_STRINGS.ARE_YOU_SURE_YOU_WANT_TO_SKIP_BREAK
        }
        isOpen={isConfirm}
        backdropDismiss={!loading}
        onDidDismiss={() => setIsConfirm(false)}
        buttons={[
          {
            text: SHIFT_STRINGS.CANCEL_SKIP_BREAK,
            role: "cancel",
            handler: () => {
              setIsConfirm(false);

              logEvent(
                USER_EVENTS.SKIP_BREAK_CANCEL,
                makeInstantpayLogParameters(
                  shift,
                  shift.isInstantPay,
                  undefined,
                  shiftStateData?.metadata.requiresLunchBreak
                )
              );
            },
          },
          {
            text: SHIFT_STRINGS.SKIP_BREAK,
            handler: onClick,
          },
        ]}
      />
    </Fragment>
  );
};
