import { logError, logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import { Shift } from "@src/lib/interface";
import { CapacitorPedometer } from "capacitor-pedometer";
import { subDays } from "date-fns";

export async function recordShiftPedometerData(shift: Shift) {
  try {
    const isStepCountingAvailable = await CapacitorPedometer.isStepCountingAvailable();

    if (!isStepCountingAvailable) {
      logEvent(USER_EVENTS.STEP_COUNTING_API_UNAVAILABLE, {
        shiftId: shift._id,
      });

      return;
    }

    const { physicalActivity } = await CapacitorPedometer.checkPermissions();
    logEvent(USER_EVENTS.PERMISSION_CHECK, {
      permissionAlias: "physicalActivity",
      status: physicalActivity,
    });

    // If the user rejected the permission, no reason to query the data
    if (physicalActivity === "denied") {
      return;
    }

    if (!shift.clockInOut?.start) {
      logError(USER_EVENTS.SHIFT_PEDOMETER_DATA_FAILURE, {
        error: "Clock In information unavailable on the shift",
        metadata: {
          shiftId: shift._id,
        },
      });
      return;
    }

    const clockInTime = shift.clockInOut.start;

    // Consider now is the clock out time
    const clockOutTime = new Date();

    const { steps } = await CapacitorPedometer.readSteps({
      startAt: clockInTime,
      endAt: clockOutTime.toISOString(),
    });

    logEvent(USER_EVENTS.SHIFT_PEDOMETER_DATA, {
      shiftId: shift._id,
      numberOfStepsDuringShift: steps,
    });
  } catch (error) {
    logError(USER_EVENTS.SHIFT_PEDOMETER_DATA_FAILURE, {
      error,
      metadata: {
        shiftId: shift._id,
      },
    });
  }
}

export async function forcePedometerPermissionPrompt(shift: Shift) {
  try {
    const isStepCountingAvailable = await CapacitorPedometer.isStepCountingAvailable();

    if (!isStepCountingAvailable) {
      logEvent(USER_EVENTS.STEP_COUNTING_API_UNAVAILABLE, {
        shiftId: shift._id,
      });

      return;
    }

    const { physicalActivity } = await CapacitorPedometer.checkPermissions();
    logEvent(USER_EVENTS.PERMISSION_CHECK, {
      permissionAlias: "physicalActivity",
      status: physicalActivity,
    });

    // If the user already accepted or denied the permission, exit
    if (physicalActivity !== "prompt") {
      return;
    }

    // Use some dummy interval to read pedometer data for
    const now = new Date();
    const yesterday = subDays(now, 1);

    // Ignore the result
    await CapacitorPedometer.readSteps({
      startAt: yesterday.toISOString(),
      endAt: now.toISOString(),
    });
  } catch (error) {
    const errorCode = error["code"];
    if (errorCode && errorCode === "UNAUTHORIZED") {
      // We emit this event immediately so it's easy to check for when permissions were denied
      logEvent(USER_EVENTS.PERMISSION_CHECK, {
        permissionAlias: "physicalActivity",
        status: "denied",
      });
    } else {
      logError(USER_EVENTS.SHIFT_PEDOMETER_DATA_FAILURE, {
        error,
        metadata: {
          shiftId: shift._id,
        },
      });
    }
  }
}
