import { Text } from "@clipboard-health/ui-react";
import { IonSpinner } from "@ionic/react";
import { Dialog, DialogContent, Stack } from "@mui/material";
import { ShiftStages } from "@src/lib/interface/src";

import { getStageText } from "../Stage";

export function ClockPendingAlertDialog({
  isVisible,
  updatingStage,
}: {
  isVisible: boolean;
  updatingStage?: ShiftStages;
}) {
  const stageText = updatingStage ? getStageText(updatingStage).toLowerCase() : "undefined";

  return (
    <Dialog open={isVisible}>
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <IonSpinner name="crescent" />
          <Text>Waiting to confirm {stageText || "action"}...</Text>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
