import { IonCardSubtitle } from "@ionic/react";
import { Link } from "react-router-dom";

import {
  clockInReminderText,
  clockOutReminderText,
  forgotClockIn,
  forgotClockOut,
  instruction,
  notInstantPay,
  supportText,
} from "../../../constants/ShiftInstructionMessages";
import { RouterPath } from "../../routing/constant";

const NonIPConversionText: React.VFC<{
  hide?: boolean;
  hasMissedClockIn: boolean;
  isAutoClockedOut: boolean;
}> = ({ hide, hasMissedClockIn, isAutoClockedOut }) => {
  if (hide) {
    return <></>;
  }

  const [forgotClock, clockReminderText] = (isAutoClockedOut && [
    forgotClockOut,
    clockOutReminderText,
  ]) ||
    (hasMissedClockIn && [forgotClockIn, clockInReminderText]) || [undefined, undefined];

  return (
    <div>
      {forgotClock && (
        <IonCardSubtitle className="title no-text-transform ion-margin-top">
          {forgotClock}
        </IonCardSubtitle>
      )}
      <p>
        {notInstantPay}
        <br />
        {instruction}
        <br />
        {supportText}
        <Link to={RouterPath.NEED_HELP}>Help Center</Link>.
      </p>
      {clockReminderText && <p>{clockReminderText}</p>}
    </div>
  );
};

export { NonIPConversionText };
